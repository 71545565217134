/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import React, { ReactElement, useState, useRef } from 'react';

import { types } from '@koeajacom/ka-types';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

import { FaFloppyDisk, FaMinus } from 'react-icons/fa6';

import AgentAPI from '../../api/agent';
import ActionSpinner from '../../components/common/ActionSpinner';

interface DealershipSettingsProps {
  api: AgentAPI;
  agentProfile: types.AgentProfileWithDealership;
  setAgentProfile: (agentProfile: types.AgentProfileWithDealership) => void;
}

const DealershipSettings = ({
  api,
  agentProfile,
  setAgentProfile,
}: DealershipSettingsProps): ReactElement => {
  const [pending, setPending] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);

  const { dealership } = agentProfile;

  const [creditCheckRequired, setCreditCheckRequired] = useState<boolean>(dealership.creditCheckRequired);
  const [defaultTestDriveLength, setDefaultTestDriveLength] = useState<number>(dealership.defaultTestDriveLength);
  const [timeBetweenTestDrives, setTimeBetweenTestDrives] = useState<number>(dealership.timeBetweenTestDrives);
  const [defaultDeductible, setDefaultDeductible] = useState<number>(dealership.defaultDeductible);
  const [openingHours, setOpeningHours] = useState<types.OpeningHours>(JSON.parse(dealership.openingHours) as types.OpeningHours);

  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);

  const weekDays = [
    { num: '1', name: 'mon' },
    { num: '2', name: 'tue' },
    { num: '3', name: 'wed' },
    { num: '4', name: 'thu' },
    { num: '5', name: 'fri' },
    { num: '6', name: 'sat' },
    { num: '7', name: 'sun' },
  ];

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === true) {
      setPending(true);

      api.updateDealershipSettings({
        creditCheckRequired,
        defaultTestDriveLength,
        timeBetweenTestDrives,
        defaultDeductible,
        openingHours: JSON.stringify(openingHours),
      }).then((ap) => { setAgentProfile(ap); setPending(false); setValidated(false); })
        .catch(() => { setPending(false); });
    }

    setValidated(true);
  };

  const updateWeekdayOpeningHours = (day: string, which: string, newTime: string) => {
    setOpeningHours({
      ...openingHours,
      weekDays: {
        ...openingHours.weekDays,
        [day]: {
          ...openingHours.weekDays[day as unknown as keyof typeof openingHours.weekDays],
          [which]: newTime,
        },
      },
    });
  };

  return (
    <Row className="justify-content-center px-2 my-3">
      <Col xs={12} md={10} lg={7} xxl={6}>
        <h3>{t('agent-dealershipSettings-title')}</h3>
        <Form
          noValidate
          validated={validated}
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <Row>
            <Col xs={12} className="mb-3">
              <Form.Group>
                <Form.Label>{t('agent-dealershipSettings-form-testDriveLength')}</Form.Label>
                <InputGroup>
                  <Form.Control type="number" value={defaultTestDriveLength} onChange={(e) => setDefaultTestDriveLength(parseInt(e.target.value, 10))} required />
                  <InputGroup.Text className="bg-primary text-white">
                    min
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mb-3">
              <Form.Group>
                <Form.Label>{t('agent-dealershipSettings-form-timeBetweenTestDrives')}</Form.Label>
                <InputGroup>
                  <Form.Control type="number" value={timeBetweenTestDrives} onChange={(e) => setTimeBetweenTestDrives(parseInt(e.target.value, 10))} required />
                  <InputGroup.Text className="bg-primary text-white">
                    min
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mb-3">
              <Form.Group>
                <Form.Label>{t('agent-dealershipSettings-form-deductible')}</Form.Label>
                <InputGroup>
                  <Form.Control type="number" value={defaultDeductible} onChange={(e) => setDefaultDeductible(parseInt(e.target.value, 10))} required />
                  <InputGroup.Text className="bg-primary text-white">
                    €
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mb-3">
              <Form.Group>
                <Form.Label>{t('agent-dealershipSettings-form-creditCheckRequired')}</Form.Label>
                <Form.Check
                  type="switch"
                  label={creditCheckRequired ? t('agent-dealershipSettings-form-creditCheckRequired-yes') : t('agent-dealershipSettings-form-creditCheckRequired-no')}
                  checked={creditCheckRequired}
                  onChange={(e) => setCreditCheckRequired(e.target.checked)}
                />
              </Form.Group>
            </Col>
          </Row>
          <h5>{t('agent-dealershipSettings-form-openingHours')}</h5>
          {weekDays.map((d) => (
            <Row>
              <p className="mb-2">{t(`agent-dealershipSettings-form-openingHours-${d.name}`)}</p>
              <Col xs={5} className="mb-2">
                <Form.Group>
                  <Form.Control
                    type="input"
                    pattern="^([0-1][0-9]|2[0-3]):[0-5][0-9]$"
                    value={openingHours.weekDays[d.num as unknown as keyof typeof openingHours.weekDays].open}
                    onChange={(e) => updateWeekdayOpeningHours(d.num, 'open', e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs={2} className="mb-3 d-flex justify-content-center align-items-center">
                <FaMinus />
              </Col>
              <Col xs={5} className="mb-2">
                <Form.Group>
                  <Form.Control
                    type="input"
                    pattern="^([0-1][0-9]|2[0-3]):[0-5][0-9]$"
                    value={openingHours.weekDays[d.num as unknown as keyof typeof openingHours.weekDays].close}
                    onChange={(e) => updateWeekdayOpeningHours(d.num, 'close', e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
          ))}
          <Row>
            <Col xs={12} className="mt-3">
              <Button
                variant="primary"
                className="w-100"
                disabled={pending}
                onClick={() => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))}
              >
                <ActionSpinner pending={pending} />
                <FaFloppyDisk className="me-2" />
                {t('general-save')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default DealershipSettings;
