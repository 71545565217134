/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import React, { ReactElement } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaTrash } from 'react-icons/fa6';

interface ConfirmDeleteModalProps {
  show: boolean;
  title: string;
  body: ReactElement;
  handleClose: () => void;
  action: () => void;
}

const ConfirmDeleteModal = ({
  show,
  title,
  body,
  handleClose,
  action,
}: ConfirmDeleteModalProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <b>
            {title}
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>{t('general-cancel')}</Button>
        <Button variant="danger" onClick={action}>
          <FaTrash className="me-2" />
          {t('general-delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDeleteModal;
