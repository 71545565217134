/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import React, { ReactElement, useState, useEffect } from 'react';
import moment from 'moment';

import { types } from '@koeajacom/ka-types';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';

import AgentAPI from '../../api/agent';

import TestDrivePermitCard from '../../components/agent/TestDrivePermitCard';
import UpdateTradeInVehicleDetailsModal from '../../components/agent/UpdateTradeInVehicleDetailsModal';

interface TestDrivePermitsProps {
  api: AgentAPI;
  agentProfile: types.AgentProfileWithDealership | null;
}

const TestDrivePermits = ({
  api,
  agentProfile,
}: TestDrivePermitsProps): ReactElement => {
  const [testDrivePermits, setTestDrivePermits] = useState<types.TestDrivePermitWithDetailsAndCustomer[] | null>(null);
  const [pending, setPending] = useState<boolean>(false);
  const [filterOld, setFilterOld] = useState<boolean>(window.localStorage.getItem('filterOld') === 'true');
  const [filterMine, setFilterMine] = useState<boolean>(window.localStorage.getItem('filterMine') === 'true');
  const [filterToday, setFilterToday] = useState<boolean>(!(window.localStorage.getItem('filterToday') === 'false'));
  const [TDPToUpdateTradeInVehicleDetails, setTDPToUpdateTradeInVehicleDetails] = useState<types.TestDrivePermitWithDetailsAndCustomer | null>(null);

  const { t } = useTranslation();

  const handleCloseUpdateTradeInVehicleDetailsModal = () => setTDPToUpdateTradeInVehicleDetails(null);

  /**
   * Effect to fetch test drive permits from the backend on page reload.
   */
  useEffect(() => {
    api.getTestDrivePermits().then((tdps) => { setTestDrivePermits(tdps.testDrivePermits); setPending(false); })
      .catch(() => { setPending(false); });
  }, []);

  /**
   * Effect to update filters in localStorage.
   */
  useEffect(() => {
    window.localStorage.setItem('filterOld', filterOld.toString());
    window.localStorage.setItem('filterMine', filterMine.toString());
    window.localStorage.setItem('filterToday', filterToday.toString());
  }, [filterOld, filterMine, filterToday]);

  const filtered = testDrivePermits ? testDrivePermits.filter((tdp) => {
    const started = tdp.actualStartDateTime;
    const ended = tdp.actualEndDateTime;
    const running = started && !ended;
    const isPassed = moment().isAfter(moment(tdp.endDateTime));
    const startMoment = tdp.actualStartDateTime ? moment.min(moment(tdp.startDateTime), moment(tdp.actualStartDateTime)) : moment(tdp.startDateTime);
    const endMoment = tdp.actualEndDateTime ? moment.min(moment(tdp.endDateTime), moment(tdp.actualEndDateTime)) : moment(tdp.endDateTime);
    const isToday = startMoment.isBetween(moment().startOf('day'), moment().endOf('day')) || endMoment.isBetween(moment().startOf('day'), moment().endOf('day'));

    if (filterOld && !running && (ended || isPassed)) return false;
    if (filterMine && tdp.agentID !== agentProfile!.ID) return false;
    if (filterToday && !running && !isToday) return false;
    return true;
  }) : [];

  const numOfFilters = [filterOld, filterMine, filterToday].filter(Boolean).length;

  if (pending || !testDrivePermits) {
    return (
      <Alert variant="info">
        <Alert.Heading>
          <Spinner animation="border" as="span" />
          &nbsp;&nbsp;
          {t('agent-loading')}
        </Alert.Heading>
        <hr />
        <p>
          {t('agent-testDrivePermits-loading')}
        </p>
      </Alert>
    );
  }

  return (
    <>
      {TDPToUpdateTradeInVehicleDetails
        && (
          <UpdateTradeInVehicleDetailsModal
            show
            tdp={TDPToUpdateTradeInVehicleDetails}
            api={api}
            setTestDrivePermits={setTestDrivePermits}
            handleClose={handleCloseUpdateTradeInVehicleDetailsModal}
          />
        )}
      <Row className="justify-content-center py-2">
        <Col xs={12} md={10} lg={7} xxl={6}>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <span>{t('agent-testDrivePermits-filter')}</span>
                {numOfFilters > 0 ? (
                  <Badge pill bg="primary" className="ms-2">{numOfFilters}</Badge>
                ) : null}
              </Accordion.Header>
              <Accordion.Body>
                <Form.Check
                  type="switch"
                  label={t('agent-testDrivePermits-filter-today')}
                  checked={filterToday}
                  onChange={(e) => setFilterToday(e.target.checked)}
                />
                <Form.Check
                  type="switch"
                  label={t('agent-testDrivePermits-filter-old')}
                  checked={filterOld}
                  onChange={(e) => setFilterOld(e.target.checked)}
                />
                <Form.Check
                  type="switch"
                  label={t('agent-testDrivePermits-filter-mine')}
                  checked={filterMine}
                  onChange={(e) => setFilterMine(e.target.checked)}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
      <Row className="justify-content-center py-2">
        <Col xs={12} md={10} lg={7} xxl={6}>
          {filtered.length === 0 ? (
            <Alert variant="info">
              {t('agent-testDrivePermits-noTestDrivePermits')}
            </Alert>
          ) : (filtered.map((tdp) => (
            <TestDrivePermitCard
              api={api}
              tdp={tdp}
              setTestDrivePermits={setTestDrivePermits}
              setTDPToUpdateTradeInVehicleDetails={setTDPToUpdateTradeInVehicleDetails}
              key={tdp.ID}
            />
          )))}
        </Col>
      </Row>
    </>
  );
};

export default TestDrivePermits;
