/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import React, { ReactElement, useState, useEffect } from 'react';
import moment, { Moment } from 'moment';

import { types } from '@koeajacom/ka-types';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import {
  FaMessage,
  FaPhone,
  FaTrash,
  FaCar,
  FaRoad,
  FaHouse,
} from 'react-icons/fa6';

import AgentAPI from '../../api/agent';

import ActionSpinner from '../common/ActionSpinner';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import DataSourceModal from '../common/DataSourceModal';

interface TestDrivePermitCardProps {
  api: AgentAPI;
  tdp: types.TestDrivePermitWithDetailsAndCustomer;
  setTestDrivePermits: (testDrivePermits: types.TestDrivePermitWithDetailsAndCustomer[]) => void;
  setTDPToUpdateTradeInVehicleDetails: (testDrivePermit: types.TestDrivePermitWithDetailsAndCustomer) => void;
}

interface Colors {
  cardColor: string;
  textColor: string;
}

const DATE_FORMAT = 'DD.MM.YYYY HH:mm';

const TestDrivePermitCard = ({
  api,
  tdp,
  setTestDrivePermits,
  setTDPToUpdateTradeInVehicleDetails,
}: TestDrivePermitCardProps): ReactElement => {
  const [pendingL, setPendingL] = useState<boolean>(false);
  const [pendingR, setPendingR] = useState<boolean>(false);
  const [aboutToDelete, setAboutToDelete] = useState<boolean>(false);
  const [showDataSourceModal, setShowDataSourceModal] = useState<boolean>(false);

  const [currentMoment, setCurrentMoment] = useState<Moment>(moment());
  const [intervalObj, setIntervalObj] = useState<number>();

  const tick = () => setCurrentMoment(moment());

  const handleCloseConfirmDeleteModal = () => setAboutToDelete(false);

  const handleCloseDataSourceModal = () => {
    setShowDataSourceModal(false);
  };

  useEffect(() => {
    setIntervalObj(window.setInterval(tick, 10000));

    return () => {
      window.clearInterval(intervalObj);
    };
  }, []);

  const { t } = useTranslation();

  const deleteTestDrivePermit = () => {
    setPendingR(true);
    handleCloseConfirmDeleteModal();
    api.deleteTestDrivePermit(tdp.ID).then((tdps) => { setTestDrivePermits(tdps.testDrivePermits); setPendingR(false); })
      .catch(() => { setPendingR(false); });
  };

  const startTestDrivePermit = () => {
    setPendingL(true);
    api.startTestDrivePermit(tdp.ID).then((tdps) => { setTestDrivePermits(tdps.testDrivePermits); setPendingL(false); })
      .catch(() => { setPendingL(false); });
  };

  const endTestDrivePermit = () => {
    setPendingL(true);
    api.endTestDrivePermit(tdp.ID).then((tdps) => { setTestDrivePermits(tdps.testDrivePermits); setPendingL(false); })
      .catch(() => { setPendingL(false); });
  };

  const setTestDrivePermitContacted = () => {
    setPendingL(true);
    api.setTestDrivePermitContacted(tdp.ID).then((tdps) => { setTestDrivePermits(tdps.testDrivePermits); setPendingL(false); })
      .catch(() => { setPendingL(false); });
  };

  const started = tdp.actualStartDateTime;
  const ended = tdp.actualEndDateTime;
  const running = started && !ended;
  const isPassed = moment().isAfter(moment(tdp.endDateTime));
  const canStartStop = running || (!ended && !isPassed);
  const canDelete = !started;
  const canCall = true;
  const canContact = ended;
  const canUpdateTradeInVehicleDetails = started || !isPassed;

  const diffMinutes = moment(tdp.endDateTime).diff(currentMoment, 'minutes');

  let colors: Colors = {
    cardColor: '',
    textColor: '',
  };

  if (ended || isPassed) {
    colors = {
      cardColor: 'secondary',
      textColor: 'white',
    };
  }

  if (running) {
    colors = {
      cardColor: 'success',
      textColor: 'white',
    };
  }

  if (running && diffMinutes < 10) {
    colors = {
      cardColor: 'warning',
      textColor: '',
    };
  }

  if (running && diffMinutes < 0) {
    colors = {
      cardColor: 'danger',
      textColor: 'white',
    };
  }

  return (
    <>
      <DataSourceModal
        handleClose={handleCloseDataSourceModal}
        show={showDataSourceModal}
        vehicle={{ fromTraficom: tdp.fromTraficom, nextInspectionDateFromTraficom: tdp.nextInspectionDateFromTraficom }}
        tdp
      />
      <ConfirmDeleteModal
        show={aboutToDelete}
        title={t('agent-testDrivePermits-confirmDeleteTitle')}
        body={(
          <>
            <b>
              {t('agent-testDrivePermits-confirmDeleteBody')}
            </b>
            <br />
            <br />
            <b>
              {tdp.vehicleString}
            </b>
            <br />
            <b>
              {tdp.customer.firstName}
              {' '}
              {tdp.customer.lastName}
            </b>
            <br />
            {t('testDrivePermit-startsAt')}
            {': '}
            {moment(tdp.startDateTime).format(DATE_FORMAT)}
            <br />
            {t('testDrivePermit-returnBy')}
            {': '}
            {moment(tdp.endDateTime).format(DATE_FORMAT)}
            <br />
            {t('general-agent')}
            {': '}
            {tdp.agentName || t('general-noAgent')}
          </>
        )}
        handleClose={handleCloseConfirmDeleteModal}
        action={deleteTestDrivePermit}
      />
      <Card
        className="mb-2"
      >
        <Card.Header className={`fs-5 fs-sm-4 fw-bold py-1 p-2 bg-${colors.cardColor}`} style={{ color: colors.textColor }}>
          {tdp.licensePlate}
          <span className="float-end">
            {running ? `${diffMinutes} min` : null}
            {canDelete ? (
              <Button
                variant="danger"
                size="sm"
                className="w-100"
                disabled={pendingL || pendingR}
                onClick={() => setAboutToDelete(true)}
              >
                <ActionSpinner pending={pendingR} />
                <FaTrash className="me-2" />
                {t('general-delete')}
              </Button>
            ) : null}
          </span>
        </Card.Header>
        <Card.Body className="p-2">
          <Row className="mt-md-5 fs-sm-5">
            <Col>
              <b>
                {tdp.vehicleString}
                {!tdp.vehicleID ? ` - ${t('general-deleted')}` : null}
              </b>
              <br />
              <b>
                {tdp.customer.firstName}
                {' '}
                {tdp.customer.lastName}
              </b>
              <br />
              {tdp.customer.phoneNumber}
              <br />
              {tdp.actualStartDateTime ? t('testDrivePermit-startedAt') : t('testDrivePermit-startsAt')}
              {': '}
              {tdp.actualStartDateTime ? moment(tdp.actualStartDateTime).format(DATE_FORMAT) : moment(tdp.startDateTime).format(DATE_FORMAT)}
              <br />
              {t('testDrivePermit-returnBy')}
              {': '}
              {moment(tdp.endDateTime).format(DATE_FORMAT)}
              <br />
              {t('general-agent')}
              {': '}
              {tdp.agentName || t('general-noAgent')}
            </Col>
          </Row>
          <Row className="mt-2 mt-sm-4 mt-md-5 fs-sm-5">
            {canStartStop ? (
              <Col xs={8} className="pe-0">
                <Button
                  variant={running ? 'primary' : 'primary'}
                  className="w-100"
                  disabled={pendingL || pendingR}
                  onClick={() => {
                    if (!started) { startTestDrivePermit(); return; }
                    if (running) { endTestDrivePermit(); }
                  }}
                >
                  <ActionSpinner pending={pendingL} />
                  {running ? (
                    <FaHouse className="me-2" />
                  ) : (
                    <FaRoad className="me-2" />
                  )}
                  {running ? t('agent-testDrivePermits-button-end') : t('agent-testDrivePermits-button-start')}
                </Button>
              </Col>
            ) : null}
            {canContact ? (
              <Col xs={8} className="pe-0">
                <Button
                  variant={tdp.contacted ? 'success' : 'primary'}
                  disabled={tdp.contacted || pendingL || pendingR}
                  className="w-100"
                  onClick={() => setTestDrivePermitContacted()}
                >
                  <ActionSpinner pending={pendingL} />
                  <FaMessage className="me-2" />
                  {tdp.contacted ? t('agent-testDrivePermits-button-alreadyContacted') : t('agent-testDrivePermits-button-setContacted')}
                </Button>
              </Col>
            ) : null}
            {canCall ? (
              <Col xs={canStartStop || canContact ? 4 : 12}>
                <Button
                  variant="primary"
                  className="w-100"
                  href={`tel:${tdp.customer.phoneNumber}`}
                >
                  <FaPhone className="me-2" />
                  {t('agent-testDrivePermits-button-call')}
                </Button>
              </Col>
            ) : null}
          </Row>
          {canUpdateTradeInVehicleDetails ? (
            <Row className="mt-2 mt-sm-4 mt-md-5 fs-sm-5">
              <Col xs={12}>
                <Button
                  variant="primary"
                  className="w-100"
                  disabled={pendingL || pendingR}
                  onClick={() => setTDPToUpdateTradeInVehicleDetails(tdp)}
                >
                  <FaCar className="me-2" />
                  {t('agent-testDrivePermits-button-updateTradeInVehicleDetails')}
                </Button>
              </Col>
            </Row>
          ) : null}
          <Row className="mt-2 mt-sm-4 mt-md-5">
            <Col className="d-flex align-items-center">
              <span className="fw-bold">{`${t('general-dataSource-vehicleDetails-title')}:`}</span>
              <Button
                size="sm"
                className="d-flex ms-2 fw-bold"
                onClick={() => setShowDataSourceModal(true)}
              >
                {t('general-dataSource-vehicleDetails-readMore')}
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default TestDrivePermitCard;
