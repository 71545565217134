import { useTranslation } from 'react-i18next';
import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';

import { AgentProfileWithDealership } from '@koeajacom/ka-types/dist/types';

// React Bootstrap components
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';

import ActionSpinner from '../common/ActionSpinner';
import AgentAPI from '../../api/agent';

interface HeaderMenuProps {
  agentProfile: AgentProfileWithDealership | null;
  setExpanded: (expanded: boolean) => void;
  api: AgentAPI;
  setAgentProfile: (agentProfile: null) => void;
}

const HeaderMenu = ({
  agentProfile,
  setExpanded,
  api,
  setAgentProfile,
}: HeaderMenuProps): ReactElement => {
  const [pending, setPending] = useState<boolean>(false);

  const { t } = useTranslation();

  const logout = () => {
    setPending(true);
    api.logout()
      .then(() => {
        setAgentProfile(null);
        setPending(false);
      })
      .catch(() => setPending(false));
  };

  if (!agentProfile || !agentProfile.ID) {
    return (
      <Nav className="justify-content-end pe-1">
        <Nav.Link as={Link} to="/login" className="p-0">
          <Button variant="primary">
            {t('navbar-login')}
          </Button>
        </Nav.Link>
      </Nav>
    );
  }

  return (
    <>
      <Navbar.Toggle className="bg-primary" />
      <Navbar.Offcanvas
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {`${agentProfile?.firstName} ${agentProfile?.lastName}`}
            <span className="d-block fs-6">{agentProfile?.dealership.name}</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="justify-content-end flex-grow-1">
            <Nav.Link as={Link} to="/koeajot" onClick={() => setExpanded(false)}>
              {t('navbar-testDrivePermits')}
            </Nav.Link>
            <Nav.Link as={Link} to="/ajoneuvot" onClick={() => setExpanded(false)}>
              {t('navbar-vehicles')}
            </Nav.Link>
            <Nav.Link as={Link} to="/asetukset" onClick={() => setExpanded(false)}>
              {t('navbar-settings')}
            </Nav.Link>
            <Nav.Link as={Link} to="/me" onClick={() => setExpanded(false)}>
              {t('navbar-me')}
            </Nav.Link>
            <Nav.Link className="navbar-nav">
              <Button
                variant="primary"
                onClick={logout}
                disabled={pending}
              >
                <ActionSpinner pending={pending} />
                {t('navbar-logout')}
              </Button>
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </>
  );
};

export default HeaderMenu;
