/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import React, { ReactElement } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

interface DataSourceModalProps {
  show: boolean;
  handleClose: () => void;
  vehicle: { fromTraficom: boolean, nextInspectionDateFromTraficom: boolean };
  tdp?: boolean;
}

const DataSourceModal = ({
  show,
  handleClose,
  vehicle,
  tdp,
}: DataSourceModalProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <b>
            {t('general-dataSource-vehicleDetails-title')}
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {vehicle.fromTraficom ? (
          <>
            <p>
              <b>{t('general-dataSource-traficom')}</b>
              {': '}
              {t('general-dataSource-vehicleDetails-alwaysTrafiom')}
              {vehicle.nextInspectionDateFromTraficom ? `, ${t('general-dataSource-vehicleDetails-inspectionTraficom')}` : null}
            </p>
            <p>
              <b>{t('general-dataSource-koeaja')}</b>
              {': '}
              {t('general-dataSource-vehicleDetails-other')}
            </p>
          </>
        ) : (
          <p>
            <b>{t('general-dataSource-koeaja')}</b>
            {': '}
            {t('general-dataSource-vehicleDetails-all')}
          </p>
        )}
        {tdp ? (
          <p>{t('general-dataSource-vehicleDetails-tdp')}</p>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>{t('general-close')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

DataSourceModal.defaultProps = {
  tdp: false,
};

export default DataSourceModal;
