import axios, { AxiosResponse } from 'axios';
import { TFunction } from 'i18next';
import { Buffer } from 'buffer';

import { headers, types } from '@koeajacom/ka-types';

import { ToastProps } from '../toaster/ToasterContext';
import ToastType from '../toaster/ToastType';
import {
  APIError,
  APIErrorMessage,
  SilentAPIError,
  universalAPIError,
} from './error';

export default class BaseAPI {
  private readonly showToast: (props: ToastProps) => void;

  private readonly setUserStatus: ((userStatus: types.UserStatus) => void) | null;

  private readonly i18n: TFunction;

  protected readonly axiosInstance = axios.create({
    withCredentials: true,
    validateStatus: null,
  });

  constructor(
    showToast: (props: ToastProps) => void,
    setUserStatus: ((userStatus: types.UserStatus) => void) | null,
    i18n: TFunction
  ) {
    this.showToast = showToast;
    this.setUserStatus = setUserStatus;
    this.i18n = i18n;

    /**
     * Run onUnauthenticatedResponse if any of the requests fails with status 401 (unauthorized).
     */
    this.axiosInstance.interceptors.response.use(
      (response) => {
        if (response?.status === 401) {
          return Promise.reject(new SilentAPIError('Unauthorized', { response }));
        }
        return response;
      },
      (error) => Promise.reject(error)
    );
  }

  /** Uses toaster to inform the user about the given APIError */
  public toastError = (err: APIError): void => {
    this.showToast({
      header: this.i18n('general-error').toString(),
      message: this.i18n(err.i18nKey).toString(),
      fryTime: 3000,
      type: ToastType.DANGER,
    });
  };

  /**
  * Helper for extracting and parsing the user status header contents into types.UserStatus
  */
  // eslint-disable-next-line class-methods-use-this
  private extractStatus = (res: AxiosResponse): types.UserStatus => {
    const header = res.headers[headers.userStatus.toLowerCase()];
    if (!header) {
      throw universalAPIError({
        message: 'Failed to find user status header from response',
        i18nKey: 'api-server-fail',
      }, res);
    }

    try {
      return JSON.parse(Buffer.from(header, 'base64').toString('utf8'));
    } catch (err) {
      throw universalAPIError({
        message: 'Failed to parse user status header from response',
        i18nKey: 'api-server-fail',
      }, res);
    }
  };

  /**
   * Sets up a whitelist rule accepting only the specified responses based on the response status
   * codes. Extracts the user's status header from response and sets it to sessionState.
   *
   * All non-whitelisted status codes will throw an error using the given i18n errorMessage.
   *
   * There are multiple ways to define the whitelists:
   *
   * ```
   * somePromise
   *   .then(this.acceptOnlyWithStatus(200, 'i18n-key-for-the-error'))
   *   .then(this.acceptOnlyWithStatus([200, 201], 'i18n-key-for-the-error'))
   * ```
   *
   * @param status status code or status codes to accept
   * @param errorMessage APIErrorMessage or i18n key for the localized error message (optional)
   */
  protected acceptOnlyWithStatus = <T>(
    status: number | number[],
    errorMessage?: APIErrorMessage | string
  ) => (res: AxiosResponse<T>): T => {
    // Run the regular acceptance logic, this will throw on error
    const body = this.acceptOnly<T>(status, errorMessage)(res);
    // Extract the user's status from the header and set it to sessionState
    const userStatus = this.extractStatus(res);
    this.setUserStatus!(userStatus);

    return body;
  };

  /**
   * Sets up a whitelist rule accepting only the specified responses based on the response status
   * codes.
   *
   * All non-whitelisted status codes will throw an error using the given i18n errorMessage.
   *
   * There are multiple ways to define the whitelists:
   *
   * ```
   * somePromise
   *   .then(this.acceptOnly(200, 'i18n-key-for-the-error'))
   *   .then(this.acceptOnly([200, 201], 'i18n-key-for-the-error'))
   * ```
   *
   * @param status status code or status codes to accept
   * @param errorMessage APIErrorMessage or i18n key for the localized error message (optional)
   */
  // eslint-disable-next-line class-methods-use-this
  protected acceptOnly = <T>(
    status: number | number[],
    errorMessage?: APIErrorMessage | string
  ) => (res: AxiosResponse<T>): T => {
    if (Array.isArray(status)) {
      // status is an array of acceptable status codes
      if (status.includes(res.status)) {
        return res.data; // Match
      }
    } else if (res.status === status) { // otherwise: status is a number indicating the status code
      return res.data;
    }

    // No whitelist matches, throw an APIError
    throw universalAPIError(errorMessage, res);
  };

  /**
   * Sets up a blacklist rule rejecting responses with a specified status code.
   *
   * On reject, throws an APIError using the given i18n errorMessage.
   *
   * Usage:
   * ```
   * somePromise
   *   .then(this.reject(404, 'i18n-key-for-non-existing-object'))
   * ```
   *
   * @param status status code to reject
   * @param errorMessage APIErrorMessage describing why the response is being rejected
   */
  // eslint-disable-next-line class-methods-use-this
  protected reject = (
    status: number,
    errorMessage: APIErrorMessage | ((res: AxiosResponse) => APIErrorMessage)
  ) => (res: AxiosResponse): AxiosResponse | never => {
    if (res.status !== status) {
      return res;
    }

    // Status code matched; throw an APIError
    const m = (typeof errorMessage === 'function') ? errorMessage(res) : errorMessage;

    throw new APIError(
      m.message,
      m.i18nKey,
      {
        response: res,
      }
    );
  };

  /**
   * Sets up a catcher that catches rejections and informs the user about the error via toasts.
   *
   * Creates a new APIError if the thrown error is not already an APIError.
   * Re-throws the error APIError.
   *
   * Usage: chaining with a promise using .catch():
   * ```
   * somePromise
   *   .then(...) // chain other tasks here
   *   .catch(this.inspectError())
   *
   * ```
   *
   * @protected
   */
  protected inspectError(): ((err: unknown) => never) {
    return (err: unknown) => {
      if (err instanceof SilentAPIError) {
        // eslint-disable-next-line no-console
        console.error(err.message);
        throw err;
      }
      const e: APIError = (() => {
        if (err instanceof APIError) {
          // Caught an APIError
          return err;
        }

        if (err instanceof Error) {
          // Caught some other error – convert it into an APIError
          return new APIError(
            'Encountered an error while communicating with the backend',
            'api-server-fail',
            {}
          );
        }

        // Caught something other than an error
        // (yes, in js it is possible to throw anything, not just the errors :D)
        return universalAPIError();
      })();

      this.toastError(e);
      // eslint-disable-next-line no-console
      console.error(e.message);

      throw e;
    };
  }
}
