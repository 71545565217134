/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import React, { ReactElement, useState, useRef } from 'react';

import { types } from '@koeajacom/ka-types';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FaFloppyDisk } from 'react-icons/fa6';

import AgentAPI from '../../api/agent';
import ActionSpinner from '../common/ActionSpinner';

interface UpdateTradeInVehicleDetailsModalProps {
  api: AgentAPI;
  show: boolean;
  tdp: types.TestDrivePermitWithDetailsAndCustomer;
  setTestDrivePermits: (testDrivePermits: types.TestDrivePermitWithDetailsAndCustomer[]) => void;
  handleClose: () => void;
}

const UpdateTradeInVehicleDetailsModal = ({
  api,
  show,
  tdp,
  setTestDrivePermits,
  handleClose,
}: UpdateTradeInVehicleDetailsModalProps): ReactElement => {
  const [pending, setPending] = useState<boolean>(false);
  const [tradeInLicensePlate, setTradeInLicensePlate] = useState<string>(tdp.tradeInLicensePlate);
  const [tradeInMileage, setTradeInMileage] = useState<string>(tdp.tradeInMileage);
  const [validated, setValidated] = useState<boolean>(false);

  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === true) {
      setPending(true);

      api.updateTestDrivePermitTradeInVehicleDetails(tdp.ID, {
        tradeInLicensePlate,
        tradeInMileage,
      }).then((tdps) => { setTestDrivePermits(tdps.testDrivePermits); handleClose(); })
        .catch(() => { setPending(false); });
    }

    setValidated(true);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <b>
            {t('agent-testDrivePermits-updateTradeInVehicleDetails')}
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          validated={validated}
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <Row>
            <Col xs={12} className="mb-3">
              <Form.Group>
                <Form.Label>{t('general-vehicle-licensePlate')}</Form.Label>
                <Form.Control type="input" value={tradeInLicensePlate} onChange={(e) => setTradeInLicensePlate(e.target.value)} required autoFocus />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mb-3">
              <Form.Group>
                <Form.Label>{t('general-vehicle-mileage')}</Form.Label>
                <Form.Control type="input" value={tradeInMileage} onChange={(e) => setTradeInMileage(e.target.value)} required />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>{t('general-cancel')}</Button>
        <Button
          variant="primary"
          disabled={pending}
          onClick={() => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))}
        >
          <ActionSpinner pending={pending} />
          <FaFloppyDisk className="me-2" />
          {t('general-save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateTradeInVehicleDetailsModal;
