import { enums, types } from '@koeajacom/ka-types';
import { TFunction } from 'i18next';

import { ToastProps } from '../toaster/ToasterContext';

import BaseAPI from './api';

/** This class is responsible for the backend communication.
 *
 * Each api endpoint has its own method here in API. Methods are returning promises that resolve
 * only after the backend replies with an accepting status code. If anything goes wrong during the
 * request, the promises returned from the API methods should reject. In that case, the API takes
 * care of displaying an informative and internationalized error toast for the user.
 */
export default class AgentAPI extends BaseAPI {
  constructor(
    showToast: (props: ToastProps) => void,
    i18n: TFunction
  ) {
    super(showToast, null, i18n);

    this.axiosInstance.defaults.baseURL = '/api/agent';
  }

  /**
   * How api endpoints are implemented:
   *
   * The API methods depend on the axios library. One notable difference is that the axios will
   * resolve with any response status code, even with the 5xx ones. The status code based validation
   * must be done in separately each method.
   *
   * To make it simpler, there exists three utility functions: reject, acceptOnly and inspectError.
   * The intended usage for these is somewhat similar to the factory model approach; but the
   * chaining of the rules is done using the native chaining methods of js Promises.
   *
   * Each method start with an axios call. The utility functions are then chained to the promise it
   * returns. The logic behind those rules tries to imitate a simple firewall rule set. They should
   * be chained in the following order:
   *   1. reject (blacklists a single response with an known error status code) (optional)
   *   2. acceptOnly (specifies a whitelist for responses to be accepted)
   *   3. inspectError (catches rejections and toasts an appropriate error message for the user)
   *
   * In most scenarios, only the acceptOnly and inspectError functions are required. Note, that one
   * reject or acceptOnly block can give only errors with one specific message. For example, the
   * acceptOnly rejects the responses with the same error message, so if some response status code
   * should lead to a more specific error message, a reject can be chained before the acceptOnly.
   *
   * Inspect error is always required and should be chained using Promise.catch. This way it catches
   * all errors thrown during any part of the promise chain, also the ones thrown by axios.
   *
   * More specific information about the utility functions can be found on their documentation.
   */

  /** Post the emailAddress and password to backend for login */
  async login(login: types.AgentLogin): Promise<types.AgentProfileWithDealership> {
    return this.axiosInstance.post('/login', login)
      .then(this.acceptOnly<types.AgentProfileWithDealership>(200, 'api-authentication-fail'))
      .catch(this.inspectError());
  }

  /** Log out the user */
  async logout(): Promise<void> {
    return this.axiosInstance.post('/logout')
      .then(this.acceptOnly<void>(204, 'api-logout-fail'))
      .catch(this.inspectError());
  }

  /** Fetch agent profile from backend */
  async me(): Promise<types.AgentProfileWithDealership> {
    return this.axiosInstance.get('/me')
      .then(this.acceptOnly<types.AgentProfileWithDealership>(200, 'api-agent-me-fail'))
      .catch(this.inspectError());
  }

  /** Fetch test drive permits from backend */
  async getTestDrivePermits(): Promise<types.TestDrivePermitsWithDetailsAndCustomer> {
    return this.axiosInstance.get('/testDrivePermits')
      .then(this.acceptOnly<types.TestDrivePermitsWithDetailsAndCustomer>(200, 'api-agent-testDrivePermits-fail'))
      .catch(this.inspectError());
  }

  /** Delete test drive permit */
  async deleteTestDrivePermit(
    testDrivePermitID: string
  ): Promise<types.TestDrivePermitsWithDetailsAndCustomer> {
    return this.axiosInstance.delete(`/testDrivePermits/${testDrivePermitID}`)
      .then(this.reject(409, (res) => {
        switch (res.data.reason) {
          case enums.DeleteTestDrivePermitErrorReason.TestDrivePermitBelongsToOtherDealership:
            return {
              message: 'Test drive permit belongs to other dealership',
              i18nKey: 'api-agent-deleteTestDrivePermit-testDrivePermitBelongsToOtherDealership',
            };
          case enums.DeleteTestDrivePermitErrorReason.TestDriveAlreadyStarted:
            return { message: 'Test drive already started', i18nKey: 'api-agent-deleteTestDrivePermit-testDriveAlreadyStarted' };
          default:
            return { message: 'Unknown 409 error', i18nKey: 'api-agent-deleteTestDrivePermit-fail' };
        }
      }))
      .then(this.acceptOnly<types.TestDrivePermitsWithDetailsAndCustomer>(200, 'api-agent-deleteTestDrivePermit-fail'))
      .catch(this.inspectError());
  }

  /** Start test drive permit */
  async startTestDrivePermit(
    testDrivePermitID: string
  ): Promise<types.TestDrivePermitsWithDetailsAndCustomer> {
    return this.axiosInstance.patch(`/testDrivePermits/${testDrivePermitID}/start`)
      .then(this.reject(409, (res) => {
        switch (res.data.reason) {
          case enums.StartTestDrivePermitErrorReason.TestDrivePermitBelongsToOtherDealership:
            return {
              message: 'Test drive permit belongs to other dealership',
              i18nKey: 'api-agent-startTestDrivePermit-testDrivePermitBelongsToOtherDealership',
            };
          case enums.StartTestDrivePermitErrorReason.TestDriveAlreadyStarted:
            return { message: 'Test drive already started', i18nKey: 'api-agent-startTestDrivePermit-testDriveAlreadyStarted' };
          case enums.StartTestDrivePermitErrorReason.VehicleAlreadyHasRunningTestDrive:
            return {
              message: 'Vehicle already has running test drive',
              i18nKey: 'api-agent-startTestDrivePermit-vehicleAlreadyHasRunningTestDrive',
            };
          default:
            return { message: 'Unknown 409 error', i18nKey: 'api-agent-startTestDrivePermit-fail' };
        }
      }))
      .then(this.acceptOnly<types.TestDrivePermitsWithDetailsAndCustomer>(200, 'api-agent-startTestDrivePermit-fail'))
      .catch(this.inspectError());
  }

  /** End test drive permit */
  async endTestDrivePermit(
    testDrivePermitID: string
  ): Promise<types.TestDrivePermitsWithDetailsAndCustomer> {
    return this.axiosInstance.patch(`/testDrivePermits/${testDrivePermitID}/end`)
      .then(this.reject(409, (res) => {
        switch (res.data.reason) {
          case enums.EndTestDrivePermitErrorReason.TestDrivePermitBelongsToOtherDealership:
            return {
              message: 'Test drive permit belongs to other dealership',
              i18nKey: 'api-agent-endTestDrivePermit-testDrivePermitBelongsToOtherDealership',
            };
          case enums.EndTestDrivePermitErrorReason.TestDriveNotStarted:
            return { message: 'Test drive not started', i18nKey: 'api-agent-endTestDrivePermit-testDriveNotStarted' };
          case enums.EndTestDrivePermitErrorReason.TestDriveAlreadyEnded:
            return { message: 'Test drive already ended', i18nKey: 'api-agent-endTestDrivePermit-testDriveAlreadyEnded' };
          default:
            return { message: 'Unknown 409 error', i18nKey: 'api-agent-endTestDrivePermit-fail' };
        }
      }))
      .then(this.acceptOnly<types.TestDrivePermitsWithDetailsAndCustomer>(200, 'api-agent-endTestDrivePermit-fail'))
      .catch(this.inspectError());
  }

  /** Set the contacted status of a test drive permit */
  async setTestDrivePermitContacted(
    testDrivePermitID: string
  ): Promise<types.TestDrivePermitsWithDetailsAndCustomer> {
    return this.axiosInstance.patch(`/testDrivePermits/${testDrivePermitID}/setContacted`)
      .then(this.reject(409, (res) => {
        switch (res.data.reason) {
          case enums.SetTestDrivePermitContactedErrorReason.TestDrivePermitBelongsToOtherDealership:
            return {
              message: 'Test drive permit belongs to other dealership',
              i18nKey: 'api-agent-setTestDrivePermitContacted-testDrivePermitBelongsToOtherDealership',
            };
          default:
            return { message: 'Unknown 409 error', i18nKey: 'api-agent-setTestDrivePermitContacted-fail' };
        }
      }))
      .then(this.acceptOnly<types.TestDrivePermitsWithDetailsAndCustomer>(200, 'api-agent-setTestDrivePermitContacted-fail'))
      .catch(this.inspectError());
  }

  /** Update the trade-in vehicle details of a test drive permit */
  async updateTestDrivePermitTradeInVehicleDetails(
    testDrivePermitID: string,
    tradeInVehicleDetails: types.TradeInVehicleDetails
  ): Promise<types.TestDrivePermitsWithDetailsAndCustomer> {
    return this.axiosInstance.patch(`/testDrivePermits/${testDrivePermitID}/tradeInVehicleDetails`, tradeInVehicleDetails)
      .then(this.reject(409, (res) => {
        switch (res.data.reason) {
          case enums.UpdateTestDrivePermitTradeInVehicleDetailsErrorReason.TestDrivePermitBelongsToOtherDealership:
            return {
              message: 'Test drive permit belongs to other dealership',
              i18nKey: 'api-agent-updateTestDrivePermitTradeInVehicleDetails-testDrivePermitBelongsToOtherDealership',
            };
          default:
            return { message: 'Unknown 409 error', i18nKey: 'api-agent-updateTestDrivePermitTradeInVehicleDetails-fail' };
        }
      }))
      .then(this.acceptOnly<types.TestDrivePermitsWithDetailsAndCustomer>(
        200,
        'api-agent-updateTestDrivePermitTradeInVehicleDetails-fail'
      ))
      .catch(this.inspectError());
  }

  /** Fetch vehicle reg info from backend by license plate */
  async getVehicleRegInfoByLicensePlate(
    vehicleTypeCode: string,
    licensePlate: string
  ): Promise<types.VehicleRegInfo> {
    return this.axiosInstance.get(`/vehicleRegInfo/lp/${vehicleTypeCode}/${licensePlate}`)
      .then(this.acceptOnly<types.VehicleRegInfo>(200, 'api-agent-vehicleRegInfo-fail'))
      .catch(this.inspectError());
  }

  /** Fetch vehicle reg info from backend by VIN */
  async getVehicleRegInfoByVIN(
    vehicleTypeCode: string,
    vin: string
  ): Promise<types.VehicleRegInfo> {
    return this.axiosInstance.get(`/vehicleRegInfo/vin/${vehicleTypeCode}/${vin}`)
      .then(this.acceptOnly<types.VehicleRegInfo>(200, 'api-agent-vehicleRegInfo-fail'))
      .catch(this.inspectError());
  }

  /** Fetch vehicles from backend */
  async getVehicles(): Promise<types.Vehicles> {
    return this.axiosInstance.get('/vehicles')
      .then(this.acceptOnly<types.Vehicles>(200, 'api-agent-vehicles-fail'))
      .catch(this.inspectError());
  }

  /** Add vehicle */
  async addVehicle(
    newVehicle: types.NewOrUpdatedVehicle
  ): Promise<types.Vehicles> {
    return this.axiosInstance.post('/vehicles', newVehicle)
      .then(this.reject(409, (res) => {
        switch (res.data.reason) {
          case enums.AddVehicleErrorReason.LicensePlateAlreadyExists:
            return { message: 'License plate already exists', i18nKey: 'api-agent-addVehicle-licensePlateAlreadyExists' };
          default:
            return { message: 'Unknown 409 error', i18nKey: 'api-agent-addVehicle-fail' };
        }
      }))
      .then(this.acceptOnly<types.Vehicles>(200, 'api-agent-addVehicle-fail'))
      .catch(this.inspectError());
  }

  /** Delete vehicle */
  async deleteVehicle(
    vehicleID: string
  ): Promise<types.Vehicles> {
    return this.axiosInstance.delete(`/vehicles/${vehicleID}`)
      .then(this.reject(409, (res) => {
        switch (res.data.reason) {
          case enums.DeleteVehicleErrorReason.VehicleBelongsToOtherDealership:
            return {
              message: 'Vehicle belongs to other dealership',
              i18nKey: 'api-agent-deleteVehicle-vehicleBelongsToOtherDealership',
            };
          case enums.DeleteVehicleErrorReason.VehicleHasOngoingOrUpcomingTestDrive:
            return {
              message: 'Vehicle has ongoing or upcoming test drive permit',
              i18nKey: 'api-agent-deleteVehicle-vehicleHasOngoingOrUpcomingTestDrive',
            };
          default:
            return { message: 'Unknown 409 error', i18nKey: 'api-agent-deleteVehicle-fail' };
        }
      }))
      .then(this.acceptOnly<types.Vehicles>(200, 'api-agent-deleteVehicle-fail'))
      .catch(this.inspectError());
  }

  /** Update vehicle */
  async updateVehicle(
    vehicleID: string,
    updatedVehicle: types.NewOrUpdatedVehicle
  ): Promise<types.Vehicles> {
    return this.axiosInstance.patch(`/vehicles/${vehicleID}`, updatedVehicle)
      .then(this.reject(409, (res) => {
        switch (res.data.reason) {
          case enums.UpdateVehicleErrorReason.LicensePlateAlreadyExists:
            return { message: 'License plate already exists', i18nKey: 'api-agent-updateVehicle-licensePlateAlreadyExists' };
          case enums.UpdateVehicleErrorReason.VehicleBelongsToOtherDealership:
            return {
              message: 'Vehicle belongs to other dealership',
              i18nKey: 'api-agent-updateVehicle-vehicleBelongsToOtherDealership',
            };
          default:
            return { message: 'Unknown 409 error', i18nKey: 'api-agent-updateVehicle-fail' };
        }
      }))
      .then(this.acceptOnly<types.Vehicles>(200, 'api-agent-updateVehicle-fail'))
      .catch(this.inspectError());
  }

  /** Update dealership settings */
  async updateDealershipSettings(
    updatedDealershipSettings: types.UpdatedDealershipSettings
  ): Promise<types.AgentProfileWithDealership> {
    return this.axiosInstance.patch('/dealership', updatedDealershipSettings)
      .then(this.acceptOnly<types.AgentProfileWithDealership>(200, 'api-agent-updateDealershipSettings-fail'))
      .catch(this.inspectError());
  }
}
