/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import React, { ReactElement, useState } from 'react';
import { Navigate } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import { UserStatus } from '@koeajacom/ka-types/dist/types';

import CustomerAPI from '../../api/customer';
import ActionSpinner from '../../components/common/ActionSpinner';

interface HomeProps {
  userStatus: UserStatus | null;
  api: CustomerAPI;
}

const Home = ({
  userStatus,
  api,
}: HomeProps): ReactElement => {
  const [licensePlate, setLicensePlate] = useState<string>('');
  const [pending, setPending] = useState<boolean>(false);

  const { t } = useTranslation();

  const selectVehicle = () => {
    setPending(true);
    api.selectVehicle(licensePlate)
      .catch(() => setPending(false));
  };

  if (userStatus?.testDrivePermit) return <Navigate to="/koeajo" replace />;
  if (userStatus?.vehicle) return <Navigate to="/koeaja" replace />;

  return (
    <Row className="justify-content-center">
      <Col xs={12} md={10} lg={7} xxl={6}>
        <Row>
          <Col className="justify-content-center d-flex">
            <img
              alt="logo"
              src="img/koeajacom_logo.png"
              width="80%"
              className="mt-logo"
            />
          </Col>
        </Row>
        <Row>
          <Col className="justify-content-center d-flex mt-logo">
            <ul className="list-unstyled m-0">
              <li className="d-flex align-items-center">
                <span className="d-flex align-items-center justify-content-center rounded-circle me-3 fs-5 fs-sm-4 fs-md-3 fs-lg-2 bg-primary fw-bold text-white bullet">1</span>
                <span className="fs-5 fs-sm-4 fs-md-3 fs-lg-2 fw-bold">{t('home-instructions-1')}</span>
              </li>
              <li className="d-flex align-items-center mt-3">
                <span className="d-flex align-items-center justify-content-center rounded-circle me-3 fs-5 fs-sm-4 fs-md-3 fs-lg-2 bg-primary fw-bold text-white bullet">2</span>
                <span className="fs-5 fs-sm-4 fs-md-3 fs-lg-2 fw-bold">{t('home-instructions-2')}</span>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Form onSubmit={(e) => {
            e.preventDefault();
            if (licensePlate) selectVehicle();
          }}
          >
            <Col className="justify-content-center d-flex mt-logo">
              <InputGroup size="lg" style={{ width: '90%' }}>
                <InputGroup.Text className="border-primary border-end-0 bg-primary text-white px-3 flex-column justify-content-around align-items-center">
                  <span>
                    <img
                      alt="eu stars"
                      src="img/eu.svg"
                      width="30px"
                    />
                  </span>
                  <span className="fw-bold">FIN</span>
                </InputGroup.Text>
                <Form.Control
                  className="border-primary border-start-0 text-center fs-1 fw-bold text-uppercase"
                  placeholder="ABC-123"
                  value={licensePlate}
                  onChange={(e) => { setLicensePlate(e.target.value); }}
                />
              </InputGroup>
            </Col>
          </Form>
        </Row>
        <Row>
          <Col className="justify-content-center d-flex mt-logo">
            <Button
              className="fw-bold"
              size="lg"
              style={{ width: '90%' }}
              disabled={!licensePlate || pending}
              onClick={() => { selectVehicle(); }}
            >
              <ActionSpinner pending={pending} />
              {t('general-continue')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Home;
